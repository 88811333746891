import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import "./signup.css";

function objToString(obj) {
  return Object.entries(obj).reduce((str, [p, val]) => {
    return `${str}${p}::${val}\n`;
  }, "");
}

function SignUp(props) {
  var validate = require("validate.js");

  const recaptchaRef = useRef("");

  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");

  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailChangeHandler = (e) => {
    var email = e.target.value;
    if (validate({ from: email }, { from: { email: true } }) !== undefined) {
      setEmailError("Email введено не вірно");
    } else {
      setEmailError("");
      setEmail(email);
    }
  };

  const passwordChangeHandler = (e) => {

    var errors = [];
    var password = e.target.value;
    setPasswordError("");
    setPassword(password);

    var minConstraints = {
      password: {
        presence: true,
        length: {
          minimum: 12,
          message: "Your password must be at least 12 characters long",
        },
      },
    };

    if (
      validate({ password: password }, minConstraints, {
        format: "detailed",
      }) !== undefined
    ) {
      errors.push("Пароль має бути не менше 12 символів");
    }

    var maxConstraints = {
      password: {
        presence: true,
        length: {
          maximum: 40,
          message: "You password cannot be longer than 40 characters",
        },
      },
    };

    if (
      validate({ password: password }, maxConstraints, {
        format: "detailed",
      }) !== undefined
    ) {
      errors.push("Пароль має бути не більше 40 символів");
    }

    if (!/\D*\d/.test(password)) {
      errors.push("Your password must containe at lease 1 digit");
    }

    if (!/[^A-Z]*[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter");
    }

    setPasswordError(
      <>
        {errors.map((item, index) => (
          <div className="text-danger">{item}</div>
        ))}
      </>
    );

  };

  const repeatPasswordChangeHandler = (e) => {
    var repeatPassword = e.target.value;

    if (password !== repeatPassword) {
      setRepeatPasswordError("Паролі не співпадають");
    } else {
      setRepeatPasswordError("");
      setRepeatPassword(repeatPassword);
    }
  };

  const recaptchaChangeHandler = (value) => {
    setCaptcha(value);
    setCaptchaError("");
  };

  const onRecaptchaExpiredHandler = () => {
    recaptchaRef.current.reset();
    setCaptchaError("Введіть капчу знову");
  };

  const onRecaptchaErrHandler = () => {
    recaptchaRef.current.reset();
    setCaptchaError("Введіть капчу знову");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue == "") {
      setCaptchaError("Введіть капчу");
    } else {
      setCaptchaError("");
    }

    recaptchaRef.current.reset();

    if (
      emailError === "" &&
      passwordError === "" &&
      repeatPasswordError === "" &&
      captchaError === ""
    ) {
      axios
        .post("http://127.0.0.1:8000/api/register", {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status === "success") {
              setStatus(
                <div className="text-success">{response.data.msg}</div>
              );
            }

            if (response.data.status === "error") {
              if (typeof response.data === "object") {
                /*
                  Handle password validation errors
                */
                if (response.data.msg.passwordValidation !== undefined) {
                  setStatus(
                    <>
                      {response.data.msg.passwordValidation.map(
                        (item, index) => (
                          <div className="text-danger">{item}</div>
                        )
                      )}
                    </>
                  );
                } else {
                  setStatus(
                    <div className="text-danger">{response.data.msg}</div>
                  );
                }
              }
            }
          } else {
            setStatus(
              <div className="text-danger">Спробуйте будь ласка ще раз</div>
            );
          }
        });
    }
  };

  return (
    <>
      <Form id="SignUp" onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-center fw-bold">
          {status && <>{status}</>}
        </Form.Group>
        {/* User Email Input */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            <i className="fa-solid fa-at"></i>
            Адреса електронної пошти ( EMAIL )
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Введіть ваш email"
            onChange={emailChangeHandler}
          />
          {emailError && <div className="text-danger">{emailError}</div>}
          <Form.Text className="text-muted">
            Ми ніколи нікому не передамо вашу електронну пошту.
          </Form.Text>
        </Form.Group>
        {/* End User Email Input */}

        {/* User Password Input */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>
            <i className="fa-solid fa-lock"></i> Пароль
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Введіть пароль"
            onChange={passwordChangeHandler}
          />
          {passwordError && <div className="text-danger">{passwordError}</div>}
        </Form.Group>
        {/* End Password Input */}

        {/* User Password repeat Input */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>
            <i className="fa-solid fa-lock"></i> Пароль ще раз
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Повторіть ввод паролю"
            onChange={repeatPasswordChangeHandler}
          />
          {repeatPasswordError && (
            <div className="text-danger">{repeatPasswordError}</div>
          )}
        </Form.Group>
        {/* End Password repeat Input */}

        {/* Recaptcha */}
        <Form.Group className="mb-3">
          <ReCAPTCHA
            className="g-recaptcha"
            ref={recaptchaRef}
            onErrored={onRecaptchaErrHandler}
            onChange={recaptchaChangeHandler}
            onExpired={onRecaptchaExpiredHandler}
            sitekey={process.env.REACT_APP_SITE_KEY}
          />
        </Form.Group>
        {/* End Recaptcha */}
        {captchaError && <div className="text-danger">{captchaError}</div>}
      </Form>
    </>
  );
}

export default SignUp;
