import "./index.css";
import { useState, useRef } from "react";
import ModalSignUp from "../modal/ModalSignUp";
import ModalLogin from "../modal/ModalLogin";

function IndexBlock(props) {
  const [loginModalShow, setLoginModalShow] = useState(false);

  const [signUpModalShow, setSignUpModalShow] = useState(false);

  const loginModalHide = () => {
    setLoginModalShow(false);
  };

  const SignUpModalHide = () => {
    setSignUpModalShow(false);
  };

  function modalsHide() {
    setLoginModalShow(false);
    setSignUpModalShow(false);
  }

  const loginModalOpen = () => {
    // modalsHide();
    setLoginModalShow(true);
  };

  const signUpModalOpen = () => {
    modalsHide();
    setSignUpModalShow(true);
  };

  return (
    <>
      <div id="intro" className="bg-image vh-100 shadow-1-strong">
        <video className="videoBackground" playsInline autoPlay muted loop>
          <source
            className="h-100"
            src="https://mdbootstrap.com/img/video/Lines.mp4"
            type="video/mp4"
          />
        </video>
        <div className="mask">
          <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div className="text-white">
              <h1 className="mb-3">Домены и хостинг</h1>
              <h5 className="mb-4">
                Оказываем все услуги, которые нужны вашему сайту
              </h5>
              <div
                className="btn btn-outline-light btn-lg m-2"
                role="button"
                rel="nofollow"
                target="_blank"
                onClick={() => loginModalOpen()}
              >
                Зарегистрировать домен
              </div>
              <div
                className="btn btn-outline-light btn-lg m-2"
                target="_blank"
                role="button"
                onClick={() => signUpModalOpen()}
              >
                Заказать хостинг
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SignUpForm */}
      <ModalSignUp
        show={signUpModalShow}
        setHide={() => setSignUpModalShow(false)}
        openLoginModal={() => loginModalOpen()}
      />
      <ModalLogin show={loginModalShow} setHide={() => loginModalHide(false)} />
    </>
  );
}

export default IndexBlock;
