import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SignUp from "../forms/Signup";

function ModalSignUpBlock(props) {
  
  const { show, onClose } = props;

  const submitForm = () => {
    props.onSubmitHook();
  };

  const onCloseHook = () => {
    props.setHide();
  };

  const openLoginModal = () => {
    onCloseHook();
    props.openLoginModal();
  };

  return (
    <>
      <Modal show={show} onHide={onCloseHook}>
        <Modal.Header closeButton>
          <Modal.Title>Реєстрація</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignUp />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={openLoginModal}>
            Вхід
          </Button>
          <Button variant="success" type="submit" form="SignUp">
            Зареєструватись
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSignUpBlock;
