import { Helmet } from "react-helmet";
import "./recaptcha.css";

function Recaptcha() {
  return (
    <>
      {/* Recaptcha v2 api.js */}
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <div
        className="g-recaptcha"
        data-sitekey={process.env.REACT_APP_SITE_KEY}
      ></div>
    </>
  );
}

export default Recaptcha;
