import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Recaptcha from "../UI/recaptcha.js";
import "./signup.css";

function Login(props) {
  const emailChangeHandler = (event) => {
    console.log(event.target.value);
  };

  const submitHook = (event) => {
    event.preventDefault();
    console.log(event);
  };

  return (
    <>
      <Form id="SignUp" onSubmit={submitHook}>
        {/* User Email Input */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Адреса електронної пошти ( EMAIL )</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={emailChangeHandler}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        {/* End User Email Input */}

        {/* User Password Input */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
        {/* End Password Input */}

        {/* Recaptcha */}
        <Form.Group className="mb-3">
          <Recaptcha />
        </Form.Group>
        {/* End Recaptcha */}
      </Form>
    </>
  );
}

export default Login;
