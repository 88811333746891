import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Login from "../forms/Login";

function ModalLogin(props) {
  const submitForm = () => {
    props.onSubmitHook();
  };

  const onCloseHook = () => {
    props.setHide();
  };

  const { show, onClose } = props;

  return (
    <>
      <Modal show={show} onHide={onCloseHook}>
        <Modal.Header closeButton>
          <Modal.Title>Авторізация</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" form={props.openLoginModal}>
            Вхід
          </Button>
          <Button variant="primary" type="submit" form={props.form}>
            Зареєструватись
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLogin;
