import "./App.css";

// Fonts
import "./fonts/Roboto/css2.css";

// Libs
import "./libs/font-awesome/6.0.0/css/all.min.css";
import "./libs/mdb/mdb.min.css";

import NavBlock from "./components/nav/NavBlock";
import IndexBlock from "./components/UI/indexBlock";



function App() {
  return (
    <>
      <NavBlock />
      <IndexBlock />
    </>
  );
}

export default App;
